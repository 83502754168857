// Imports
import InfiniteScroll from 'infinite-scroll';

// Project modules
import Analytics from '@js/modules/Analytics';
import DiscoveryInsights from "@js/discovery/insights/DiscoveryInsights";

const attr = 'data-endless-scroll';

/**
 * Usage
 *
 * <div data-endless-scroll="news/p{{#}}">
 *     <div class="item">...</div>
 *     <div class="item">...</div>
 * </div>
 *
 * Set to a string with {{#}} for the incremental page number.
 * path: 'news/p{{#}}'
 * replace {{#}} with page number => news/p2, news/p3, news/p4
 */

class EndlessScroll {
    constructor() {
        this.init();
    }
    init() {
        const endlessScrollEl = document.querySelector(`[${attr}]`);

        if (endlessScrollEl) {
            const endlessScrollPath = endlessScrollEl.getAttribute(attr);

            let infScroll = new InfiniteScroll(endlessScrollEl, {
                append: '.item',
                checkLastPage: '.endless-scroll-next',
                history: false,
                path: endlessScrollPath,
                scrollThreshold: 400,
            });

            infScroll.on('request', (path, fetchPromise) => {
                console.log('Request: ', path);

                // Show loading placeholder while content is loading
                const loadingPlaceholder = document.createElement('div');

                // Alternative:
                // https://play.tailwindcss.com/NCaxgRJEhn

                // https://play.tailwindcss.com/xrnHvtisvU
                loadingPlaceholder.innerHTML = `
                    <div class="animate-pulse">
                        <div class="mb-12 w-full">
                            <div class="flex flex-row items-center mb-5">
                                <div class="bg-neutral-200 h-6 mr-2 rounded-full w-6"></div>
                                <div class="bg-neutral-200 h-2 w-[82px]"></div>
                            </div>
                            <div class="bg-neutral-200 h-5 mb-4 w-2/5"></div>
                            <div class="bg-neutral-200 h-5 mb-4 w-4/5"></div>
                        </div>

                        <div class="mb-12 w-full">
                            <div class="flex flex-row items-center mb-5">
                                <div class="bg-neutral-200 h-6 mr-2 rounded-full w-6"></div>
                                <div class="bg-neutral-200 h-2 w-[82px]"></div>
                            </div>
                            <div class="bg-neutral-200 h-5 mb-4 w-2/5"></div>
                            <div class="bg-neutral-200 h-5 mb-4 w-4/5"></div>
                        </div>
                    </div>
                `;

                endlessScrollEl.appendChild(loadingPlaceholder);

                fetchPromise.then(() => {
                    // Remove the loading placeholder once content is loaded
                    if (loadingPlaceholder.parentNode === endlessScrollEl) {
                        endlessScrollEl.removeChild(loadingPlaceholder);
                    }
                });
            });

            infScroll.on('append', (body, path, items, response) => {
                // Set page analytics
                Analytics.trackPageView(path);

                // Reinit click tracking on elements
                // Loop over items and pass item, as it isn't possible to iterable over node list
                items.forEach(function(item) {
                    DiscoveryInsights.trackClicks(item);
                });
            });
        }
    }
}

export default new EndlessScroll();
